/* 23. Checkout */
.coupon-accordion h3 {
  background-color: #fff1f0;
  border-top: 3px solid #dd5cac;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 25px;
  padding: 1em 2em 1em 3.5em;
  position: relative;
  width: auto;
}
.coupon-accordion h3::before {
  content: "\f07b";
  left: 15px;
  top: 13px;
  position: absolute;
  color: #6f7172;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
}
.coupon-accordion span {
  color: #6f7172;
  cursor: pointer;
  transition: 0.3s;
}
.coupon-accordion span:hover,
p.lost-password a:hover {
  color: #dd5cac;
}
.coupon-content {
  border: 1px solid #eaedff;
  display: none;
  margin-bottom: 20px;
  padding: 30px;
}
.coupon-info p.coupon-text {
  margin-bottom: 15px;
}
.coupon-info p {
  margin-bottom: 0;
}
.coupon-info p.form-row-first label,
.coupon-info p.form-row-last label {
  display: block;
  color: #6f7172;
}
.coupon-info p.form-row-first label span.required,
.coupon-info p.form-row-last label span.required {
  color: red;
  font-weight: 700;
}
.coupon-info p.form-row-first input,
.coupon-info p.form-row-last input {
  border: 1px solid #eaedff;
  height: 45px;
  margin: 0 0 14px;
  max-width: 100%;
  padding: 0 0 0 10px;
  width: 100%;
}
.coupon-info p.form-row input[type="submit"]:hover,
p.checkout-coupon input[type="submit"]:hover {
  background: #dd5cac none repeat scroll 0 0;
}
.coupon-info p.form-row input[type="checkbox"] {
  position: relative;
  top: 2px;
}
.form-row > label {
  margin-top: 15px;
  margin-left: 15px;
  color: #6f7172;
}
.buttons-cart input,
.coupon input[type="submit"],
.buttons-cart a,
.coupon-info p.form-row input[type="submit"] {
  background: #252525 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  display: inline-block;
  float: left;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  padding: 0 15px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
p.lost-password {
  margin-top: 15px;
}
p.lost-password a {
  color: #6f6f6f;
}
p.checkout-coupon input[type="text"] {
  height: 45px;
  padding: 0 15px;
  width: 100%;
  border: 1px solid #eaedff;
  margin-bottom: 15px;
}
.coupon-checkout-content {
  display: none;
}
.checkbox-form h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 26px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}
.country-select {
  margin-bottom: 30px;
  position: relative;
}
.country-select label,
.checkout-form-list label {
  color: #6f7172;
  display: block;
  margin: 0 0 5px;
}
.country-select label span.required,
.checkout-form-list label span.required {
  color: red;
}
.country-select select {
  -moz-appearance: none;
  border: 1px solid #eaedff;
  height: 45px;
  padding-left: 10px;
  width: 100%;
  color: #6f7172;
}
.country-select::before {
  content: "\f107";
  right: 15px;
  top: 38px;
  position: absolute;
  color: #6f7172;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
}
.checkout-form-list {
  margin-bottom: 30px;
}
.checkout-form-list label {
  color: #6f7172;
}
.checkout-form-list input[type="text"],
.checkout-form-list input[type="password"],
.checkout-form-list input[type="email"] {
  background: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  height: 45px;
  padding: 0 0 0 10px;
  width: 100%;
}
.checkout-form-list input[type="text"]::-moz-placeholder,
.checkout-form-list input[type="password"]::-moz-placeholder,
.checkout-form-list input[type="email"]::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}
.checkout-form-list input[type="text"]::placeholder,
.checkout-form-list input[type="password"]::placeholder,
.checkout-form-list input[type="email"]::placeholder {
  color: #6f7172;
  opacity: 1;
}
.checkout-form-list input[type="checkbox"] {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 1px;
}
.create-acc label {
  color: #6f7172;
  display: inline-block;
}
.create-account {
  display: none;
}
.ship-different-title h3 label {
  display: inline-block;
  margin-right: 20px;
  color: #6f7172;
}
.order-notes textarea {
  border: 1px solid #eaedff;
  height: 90px;
  padding: 15px;
  width: 100%;
}
.order-notes textarea::-moz-placeholder {
  color: #6f7172;
  opacity: 1;
}
.order-notes textarea::placeholder {
  color: #6f7172;
  opacity: 1;
}
#ship-box-info {
  display: none;
}
.panel-group .panel {
  border-radius: 0;
}
.panel-default > .panel-heading {
  border-radius: 0;
}
.your-order {
  padding: 30px 40px 45px;
  border: 3px solid #eaedff;
}
@media (max-width: 767px) {
  .your-order {
    padding: 15px;
  }
}
.your-order h3 {
  border-bottom: 1px solid #eaedff;
  font-size: 30px;
  margin: 0 0 20px;
  padding-bottom: 10px;
  width: 100%;
}
.your-order-table table {
  background: none;
  border: 0;
  width: 100%;
}
.your-order-table table th,
.your-order-table table td {
  border-bottom: 1px solid #eaedff;
  border-right: medium none;
  color: #6f7172;
  font-size: 14px;
  padding: 15px 0;
  text-align: left;
}
@media (max-width: 767px) {
  .your-order-table table th,
  .your-order-table table td {
    padding-right: 10px;
  }
}
.your-order-table table th {
  border-top: medium none;
  color: #6f7172;
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;
  width: 250px;
}
.panel-body > p {
  color: #222;
}
.your-order-table table .shipping ul li input {
  position: relative;
  top: 2px;
}
.your-order-table table .shipping ul li label {
  color: #6f7172;
}
.your-order-table table .shipping th {
  vertical-align: top;
}
.your-order-table table .order-total th {
  border-bottom: 0;
  font-size: 14px;
}
.your-order-table table .order-total td {
  border-bottom: medium none;
}
.your-order-table table tr.cart_item:hover {
  background: #f9f9f9;
}
.your-order-table table tr.order-total td span {
  color: #dd5cac;
  font-size: 18px;
  font-weight: 500;
}
.payment-method {
  margin-top: 40px;
}
.panel-title > a {
  display: block;
}
.order-button-payment input {
  background: #232323 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  margin: 0px 0 0;
  padding: 0;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  width: 100%;
}
.order-button-payment input:hover {
  background: #fe4536 none repeat scroll 0 0;
}
.payment-method .btn-link {
  -moz-user-select: none;
  background: no-repeat;
  border: medium none;
  border-radius: 0;
  color: #444;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 1;
  margin-bottom: 0;
  padding: 3px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  text-decoration: none;
}
.payment-method .card {
  background-color: #ffffff;
  border: 1px solid #eaedff;
  border-radius: 0;
  margin-bottom: 10px;
}
.payment-method .accordion .card:first-of-type {
  border: 1px solid #eaedff;
}
.card-header:first-child {
  border-radius: 0;
}
.payment-method .card-header {
  background-color: #ffffff;
  border-bottom: 1px solid #eaedff;
}
.order-button-payment button {
  width: 100%;
}
