.options-list {
  list-style: none;
  padding: 0;
}

.option-item {
  margin-bottom: 15px;
}

.option-link {
  display: block;
  padding: 10px 15px;
  background-color: #dd5cac;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #dd5cac;
  transition: background-color 0.3s ease;
  font-size: 1rem;;
}

.option-link:hover {
  background-color: #c04a97;
  color: #fff;
}

.option-link i {
  margin-right: 10px;
}

/* styles.css */

/* styles.css */

/* Mobile-first styles */
.mobile-only {
  display: block;
}

.desktop-only {
  display: none;
}

/* Media query for desktop */
@media (min-width: 992px) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: block;
  }
}

.desktop-only-image {
 width: 1500px;
}
