/* 8. Fact */
.fact-map::before {
  position: absolute;
  content: "";
  background-image: url(../img/fact/map.png);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  background-position: center center;
}
.cta-satisfied {
  padding-left: 129px;
}
.single-satisfied h1 {
  color: #dd5cac;
  float: left;
  width: 140px;
  margin-right: 20px;
}
.single-satisfied h5 {
  color: #fff;
  margin-bottom: 15px;
}
.single-satisfied h5 i {
  margin-right: 5px;
}
.single-satisfied p {
  color: #899dab;
}
.single-satisfied h5,
.single-satisfied p {
  width: calc(100% - 160px);
  display: inline-block;
}

/* fact h5*/
.h5fact-wrapper > i {
  display: block;
  font-size: 40px;
  color: #dd5cac;
  margin-bottom: 25px;
}
.h5fact-wrapper > span {
  font-size: 40px;
  font-weight: 600;
  color: #dd5cac;
  font-family: "Poppins", sans-serif;
  margin-bottom: 3px;
  display: block;
}
.h5fact-wrapper > h5 {
  font-size: 14px;
  font-weight: 500;
  color: #223645;
  margin-bottom: 15px;
}
.h5fact-wrapper {
  padding-right: 30px;
}
.h5fact-border {
  border-bottom: 2px solid #f1f1f1;
}
/* fact end h5*/
