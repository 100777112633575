/* 22. Cart */
.table-content table {
  background: #ffffff;
  border-color: #eaedff;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
  margin-bottom: 0;
}
.table-content table td.product-name {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}
.table-content table td.product-name a:hover {
  color: #dd5cac;
}
.table-content table td {
  border-top: medium none;
  padding: 20px 10px;
  vertical-align: middle;
  font-size: 16px;
}
.table-content table th,
.table-content table td {
  border-bottom: 1px solid #eaedff;
  border-right: 1px solid #eaedff;
}
.product-quantity input {
  border: none;
  color: #6f7172;
  font-size: 14px;
  font-weight: normal;
  border: 0;
}
.table td,
.table th {
  border-top: 1px solid #eaedff;
}
.product-quantity > input {
  width: 80px;
  border-radius: 3px;
}
.table-content table td.product-subtotal {
  font-size: 16px;
}
.table-content table td .cart-plus-minus {
  float: none;
  margin: 0 auto;
}
.coupon-all {
  margin-top: 50px;
}
.coupon {
  float: left;
}
@media (max-width: 767px) {
  .coupon {
    float: none;
  }
}
#coupon_code {
  height: 62px;
  border: 2px solid #eaedff;
  padding: 0 15px;
  margin-right: 10px;
}
@media (max-width: 767px) {
  #coupon_code {
    margin-bottom: 15px;
  }
}
.coupon2 {
  float: right;
}
@media (max-width: 767px) {
  .coupon2 {
    float: none;
    margin-top: 15px;
  }
}
.cart-page-total {
  padding-top: 50px;
}
.cart-page-total > h2 {
  font-size: 25px;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.cart-page-total > ul {
  border: 1px solid #eaedff;
}
.cart-page-total > ul > li {
  list-style: none;
  font-size: 15px;
  color: #6f7172;
  padding: 10px 30px;
  border-bottom: 1px solid #eaedff;
  font-weight: 400;
}
.cart-page-total ul > li > span {
  float: right;
}
.cart-page-total li:last-child {
  border-bottom: 0;
}
td.product-thumbnail img {
  width: 125px;
}
